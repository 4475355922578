/*
 * @Author: jiang
 * @Date: 2021-06-11 11:20:31
 * @Description:
 */
import request from '../axios'

/**
 * 修改用户信息
 * params name 修改姓名
 * params department_id 修改所在部门id
 */
export function modifyUsers(params) {
  return request({
    method: 'post',
    url: '/api/users/modify',
    params,
  })
}
